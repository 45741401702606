import React from "react"
import ContactForm from "../components/contact"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Contact Us"
      description="Looking for the Top Website Accessibility Services Provider in USA / UK? D2i Technology is the Best DevOps companies that provides development services at affordable prices for our clients."
      keywords={['Contact D2i Technology', 'Software Development Consultation', 'Website Accessibility Services', 'Top DevOps Company', 'Affordable Development Services', 'Best Web Accessibility Provider']}
    />
    <section id="contact" className="contact">
      <div className="container" data-aos-todo="fade-up">
        <div className="section-title">
          <h1>Contact Us</h1>
        </div>
        <div className="row mt-5">
          <div className="col-lg-4">
            <div className="info">
              <div className="email">
                <i className="icofont-envelope" aria-hidden="true"></i>
                <p className="contact-label">Email:</p>
                <p>
                  <a href="mailto:info@d2itechnology.com">
                    info@d2itechnology.com
                  </a>
                </p>
              </div>
              <div className="email">
                <i className="icofont-mobile-phone" aria-hidden="true"></i>
                <p className="contact-label">Phone:</p>
                <p>
                  <a href="tel:+918265963621">+91 82659 63621</a>
                </p>
              </div>
              <div className="whatsapp">
                <i className="icofont-whatsapp" aria-hidden="true"></i>
                <p className="contact-label">WhatsApp:</p>
                <p>
                  <a href="https://api.whatsapp.com/send?phone=918265963621&text=Need%20help%20with">
                    +91 82659 63621{" "}
                  </a>
                </p>
              </div>
              <div className="schedule-call">
                <i className="icofont-phone" aria-hidden="true"></i>
                <p className="contact-label">Schedule a Call:</p>
                <p>
                  <a href="/schedule-a-call/" target="_blank" rel="noopener noreferrer">
                    Choose a convenient time to connect.
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-8 mt-5 mt-lg-0">
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
